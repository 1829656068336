import colors from "assets/theme-dark/base/colors";

// Material Dashboard 2 React helper functions

const { white } = colors;

const tableCell = {
  styleOverrides: {
    body: {
      color: white.main + " !important",
    },
    root: {
      body: {
        color: white.main + " !important",
      },
      color: white.main + " !important",
    },
  },
};

export default tableCell;
