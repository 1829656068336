// import { debounce } from 'lodash';
// import { useCallback, useRef } from 'react';

// type Callback = (...args: any[]) => void;

// export function useDebouncedCallback(callback: Callback, delay: number) {
//   const callbackRef = useRef<Callback>();
//   callbackRef.current = callback;
//   // eslint-disable-next-line
//   return useCallback(
//     debounce((...args) => callbackRef.current?.(...args), delay),
//     []
//   );
// }

import { debounce } from 'lodash';
import { useCallback, useRef } from 'react';

export function useDebouncedCallback(callback, delay) {
  const callbackRef = useRef();
  callbackRef.current = callback;
  // eslint-disable-next-line
  return useCallback(
    debounce((...args) => callbackRef.current?.(...args), delay),
    []
  );
}
