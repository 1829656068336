import { useState, useContext } from "react";
import { GlobalState } from "context/store.js";
import React from "react";
import { useParams } from "react-router-dom";
import forOfor from "assets/img/notifications/404.png";
import MDBox from "App/Views/Components/Material_ui/MDBox/index.js";
import { DepartmentTree } from "./Components/DepartmentTree";
import { ServiceFailed } from "App/Views/ErrorViews/index";
import { EmplyState } from "context/plugin";
import MasterDataInterfaceComp from "./master_data_interface";
import EmplyService from "services/Emply_service/emply.service";
import styles from "App/Views/Components/classes";
import { makeStyles } from "@material-ui/styles";

import { Typography } from "@mui/material";
const Settings = React.lazy(() => import("../Emply/Profile"));

const useStyles = makeStyles(styles);

function filterObjectsById(ids, objects) {
  const filteredObjects = [];
  for (let i = 0; i < objects.length; i++) {
    const obj = objects[i];
    if (ids.includes(obj.dataTypeId)) {
      filteredObjects.push(obj);
    }
  }
  return filteredObjects;
}

export const Departments = ({ PropsFunc, name, passedSelected }) => {
  const classes = useStyles();

  return (
     <Typography component="h4" variant='h4' className={classes.cardTitle}>
      Team is now automatically "German Team"
                </Typography>
    // <div>Team is now automatically "German Team"</div>
    // <DepartmentTree
    //   PropsFunc={PropsFunc}
    //   name={name}
    //   passedSelected={passedSelected}
    // ></DepartmentTree>
  );
};
export const CPRForProof = () => {
  const [Emply] = useContext(EmplyState);
  if (Object.entries({ ...Emply }).length === 0) {
    return null;
  }
  let arrCPR = Emply.Changable.CPR

  const editable = Emply.Editable
  const ids = [Emply.Changable.FirstName.trim(), Emply.Changable.LastName.trim(), arrCPR]
  const filteredObjects = filterObjectsById(ids, Emply.MasterData);

  const ob = {
    CPR: filteredObjects[2]?.text?.trim(),
    Name:
      filteredObjects[0]?.text?.trim() + " " + filteredObjects[1]?.text?.trim(),
  };
  return ob;
};
export const CPR_For_Other = async (employee) => {
  const data = await EmplyService.GetMasterDataIDPOther(employee)
  const cpr = data.filter((item) => item.dataTypeId === "1e65c703-3d75-428b-9e04-b54e9b3f16ff")
  return cpr[0].text
}

export const MasterDataInterface = () => {
  const [right, setRight] = useState([]);
  const [left, setLeft] = useState([]);

  return (
    <MasterDataInterfaceComp left={left} setRight={setRight} setLeft={setLeft} right={right}></MasterDataInterfaceComp>
  )
}

export default function Emply() {
  const [Store, setStore] = useContext(GlobalState);

  let { subPath } = useParams();

  const NoMatch = () => {
    return (
      <div style={{ width: "100%", height: "100vh" }}>
        <MDBox
          component="img"
          src={forOfor}
          alt="Brand"
          width="2rem"
          sx={{ width: "100%", height: "auto" }}
        />
      </div>
    );
  };

  if (subPath === "profile") {
    return (
      <>
        {Store.Emply && <Settings></Settings>}
        {!Store.Emply && <ServiceFailed></ServiceFailed>}
      </>
    );
  } else {
    return <NoMatch></NoMatch>;
  }

}
