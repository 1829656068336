/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React Base Styles
import colors from "assets/theme-dark/base/colors";
import typography from "assets/theme-dark/base/typography";

// Material Dashboard 2 React Helper Functions
import pxToRem from "assets/theme-dark/functions/pxToRem";

const { white, text, info,success, secondary, primary, error } = colors;
const { size } = typography;
const hexToRgb = (input) => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase();
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};
const contained = {
  base: {
    minHeight: pxToRem(37),
    color: text.main,
    padding: `${pxToRem(9)} ${pxToRem(24)}`,

    "&:active, &:active:focus, &:active:hover": {
      opacity: 0.85,
    },

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(16)} !important`,
    },
  },

  small: {
    minHeight: pxToRem(29),
    padding: `${pxToRem(6)} ${pxToRem(18)}`,
    fontSize: size.xs,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(12)} !important`,
    },
  },

  large: {
    minHeight: pxToRem(44),
    padding: `${pxToRem(12)} ${pxToRem(64)}`,
    fontSize: size.sm,
    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(22)} !important`,
    },
  },

  primary: {
    backgroundColor: primary.main,
    "&:hover": {
      backgroundColor: primary.main,
    },
    "&:focus:not(:hover)": {
      backgroundColor: primary.focus,
    },
  },

  error: {
    color: "black",
    backgroundColor: error.main,
    // "&:before": {
    //   position: "absolute",
    //   content: '""',
    //   top: 0,
    //   left: 0,
    //   right: 0,
    //   width: "100%",
    //   height: "100%",
    //   background: "rgba(" +
    //     hexToRgb(white.main) +
    //     ", 0.40)",
    //   // background: "white",
    //   // "linear-gradient(45deg, " + blackColor + ", " + infoColor[1] + ") !important",
    //   color: "unset",
    //   zIndex: `${0} !important`,
    //   borderRadius: "inherit",
    // },
    "&:hover": {
      backgroundColor: error.main,
    },
    "&:focus:not(:hover)": {
      backgroundColor: error.focus,
    },
  },
  secondary: {
    backgroundColor: secondary.main,
    "&:hover": {
      backgroundColor: secondary.main,
    },
    "&:focus:not(:hover)": {
      backgroundColor: secondary.focus,
    },
  },
  success: {
    backgroundColor: success.main,
    color: "black !important",
    "&:hover": {
      backgroundColor: success.main,
    },
    "&:focus:not(:hover)": {
      backgroundColor: success.focus,
      }
  },

};

export default contained;
