import { useState, useEffect, useCallback, useContext, useRef, memo } from "react";
import { GlobalState } from "context/store.js";
import { Person, PersonViewType } from "@microsoft/mgt-react";
import * as d3 from 'd3';
import { ScheduleDataService } from "services";
import ReactDOM from 'react-dom';

const NewTables = memo((props) => {
  const [Store, setStore] = useContext(GlobalState);
  const svgRef = useRef(null);

  const verticalAttr = { width: 35, height: 75, textX: 8, textY: 20 };
  const horizontalAttr = { width: 75, height: 35, textX: 10, textY: 23 };

  const [OfficeTtables, setTables] = useState([])

  const handleOnclik = useCallback((event, d) => {
    setStore({ table: d.id });
    const svg = svgRef.current
    const selected = svg.querySelectorAll('.selected')
    selected.forEach((e) => {
      e.classList.remove('selected')
      e.setAttribute('fill', '#313d50')
    })
    const g = svg.getElementById(d.id)
    const rect = g.querySelector('rect')
    rect.classList.toggle('selected')
    rect.setAttribute('fill', '#4ab471')
  }, [setStore])

  const fetchData = useCallback(async () => {
    const copy = [...props.booked]
    for (const b of copy) {
      // remove tb from string of Table key
      b.Table = b.Table.split("tb")[1]
      // if number is 1 digit add 0 in front
      if (b.Table.length === 1) {
        b.Table = `0${b.Table}`
      }
    }
    const check = await ScheduleDataService.getTables()
    for (const d of check.data) {
      d.horizontal = d.horizontal === 1 ? true : false
      d.vertical = d.vertical === 1 ? true : false
      d.selectable = d.selectable === 0 ? false : true;
      d.assigned = d.assigned === "false" ? false : d.assigned;
    }


    setTables(check.data.map((e, index) => ({
      id: e.id,
      className: 'table-class',
      x: e.x,
      y: e.y,
      selectable: copy.find((b) => b.Table === e.text) ? false : true,
      assigned: copy.find((b) => b.Table === e.text) ? copy.find((b) => b.Table === e.text)?.email : false,
      vertical: e.vertical,
      horizontal: e.horizontal,
      rect: {
        width: e.horizontal ? horizontalAttr.width : verticalAttr.width,
        height: e.horizontal ? horizontalAttr.height : verticalAttr.height,
        rx: 5,
      },
      text: {
        className: 'text-class',
        text: `${e.text}`,
        x: e.horizontal ? horizontalAttr.textX : verticalAttr.textX,
        y: e.horizontal ? horizontalAttr.textY : verticalAttr.textY,
      }
    })))
  }, [horizontalAttr, verticalAttr, props])

  const renderD3Visualization = useCallback(async () => {
    const svgElement = d3.select(svgRef.current);
    const data = [...OfficeTtables]
    // Create SVG elements for each table
    // Remove existing elements

    const tables = svgElement
      .selectAll('.table')
      .data(data, (d) => d.id)
      .enter()
      .append('g')
      .attr('class', 'table')
      .attr('id', (d) => d.id)
      .attr('data-X', (d) => d.x)
      .attr('data-Y', (d) => d.y)
      .attr('data-selectable', (d) => d.selectable)
      .attr('data-assigned', (d) => d.assigned)
      .attr('data-vertical', (d) => d.vertical)
      .attr('data-horizontal', (d) => d.horizontal)
      .attr('transform', (d) => `translate(${d.x}, ${d.y})`)

    tables
      .append('rect')
      .attr('class', (d) => d.className)
      .attr('width', (d) => d.rect.width)
      .attr('height', (d) => d.rect.height)
      .attr('rx', (d) => d.rect.rx)
      .attr('style', (d) => `pointer-events: ${d.selectable ? "all" : "none"}`)
      .on('click', (event, d) => {
        handleOnclik(event, d)
      })
      .attr('fill', (d) => `${d.selectable ? "#313d50" : "red"}`)


    tables
      .append('text')
      .attr('class', (d) => d.text.className)
      .attr('style', 'pointer-events: none;')
      .attr('transform', (d) => d.text.transform)
      .attr('x', (d) => d.text.x)
      .attr('y', (d) => d.text.y)
      .text((d) => d.text.text);

    tables.each(function (d) {
      if (d.assigned !== false) {
        const container = d3.select(this).append('foreignObject');
        container
          .attr('width', 100).attr('height', 100)
          .attr('x', (d) => d.vertical ? d.rect.width / 6 : d.rect.width / 1.5)
          .attr('y', (d) => d.vertical ? d.rect.height / 1.7 : d.rect.height / 6);

        const person = <Person personQuery={d.assigned} view={PersonViewType.avatar} showPresence={false} />;
        ReactDOM.render(person, container.node());
      }
    });

    svgElement.classed("svg-content-responsive", true)

  }, [OfficeTtables, handleOnclik]);

  // Initial rendering and whenever refresh state changes
  useEffect(() => {
    renderD3Visualization();
  }, [OfficeTtables, renderD3Visualization]);

  useEffect(() => {
    fetchData()
  }, [props.booked])



  return (
    <div class="svg-container" style={{ width: "100%", height: "80vh" }}>
      <svg ref={svgRef} preserveAspectRatio="xMinYMin meet" viewBox="0 0 800 400" className="SVGTables">
        {/* SVG elements will be rendered here */}
      </svg>
    </div>
  );
})

export default NewTables;
