import React from "react";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CalendarViewMonthOutlinedIcon from '@mui/icons-material/CalendarViewMonthOutlined';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import PaidIcon from "@mui/icons-material/Paid";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from '@mui/icons-material/Add';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import { AdminStoreProvider } from "context/store.js"
const Users = React.lazy(() => import("App/Views/Users"));
const Admins = React.lazy(() => import("App/Views/Admins/"));

class AdminWithProvider extends React.Component {
  render() {
    return (
      <AdminStoreProvider>
        <Admins></Admins>
      </AdminStoreProvider>
    );
  }
}


class AdminTwo extends React.Component {
  render() {
    return (
      <AdminStoreProvider>
        <Admins></Admins>
      </AdminStoreProvider>
    );
  }
}
const routes = [
  [
    {
      type: "title",
      title: "Dialogue Time"
    },
  ],
  [
    {
      parent: "Dashboard",
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <DashboardIcon fontSize='small'></DashboardIcon>,
      // icon: 'DashboardIcon',
      route: "/Dashboard",
      subroute: "/Dashboard",
      component: <Users />,
    },
  ],
  /*  [
     {
       parent: "Settings",
       type: "collapse",
       name: "Profile",
       key: "/User/profile",
       icon: <PersonIcon fontSize='small'></PersonIcon>,
       route: "/User/profile",
       subroute: "/User/:subPath",
       component: <Users />,
     },
   ], */
  [
    {
      type: "divider",
    },
  ],
  [
    {
      type: "title",
      title: "Schedule"
    },
  ],
  [
    {
      parent: "Schedule",
      type: "collapse",
      name: "Schedule",
      key: "/Schedule",
      route: "/User/schedule",
      subroute: "/User/:subPath",
      icon: (
        <CalendarViewMonthOutlinedIcon fontSize='small'></CalendarViewMonthOutlinedIcon>
      ),
      component: <Users />,
    }],
  [
    {
      parent: "Schedule",
      type: "collapse",
      name: "Company schedule",
      key: "/Schedule/D1",
      icon: (
        <CorporateFareOutlinedIcon fontSize='small'></CorporateFareOutlinedIcon>
      ),
      route: "/User/schedule/company",
      subroute: "/User/:subPath/:secondPath",
      component: <Users />,
    }],
  [
    {
      type: "divider",
    },
  ],
  /*  [
     {
       type: "title",
       title: "Hours"
     },
   ],
   [
     {
       parent: "Hours",
       type: "collapse",
       name: "Log hours",
       key: "logHours",
       icon: <AddIcon fontSize='small'></AddIcon>,
       route: "/User/hours/log",
       subroute: "/User/:subPath/:secondPath",
       component: <Users />,
     },], [
     {
       parent: "Hours",
       type: "collapse",
       name: "Hours overview",
       key: "See Hours",
       icon: (
         <AccountBalanceOutlinedIcon fontSize='small'></AccountBalanceOutlinedIcon>
       ),
       route: "/User/hours/overview",
       subroute: "/User/:subPath/:secondPath",
       component: <Users />,
     },
   ],
   [
     {
       type: "divider",
     },
   ], */
  [
    {
      parent: "Admins",
      type: "collapse",
      name: "Admins dashboard",
      key: "/Admins",
      icon: <DashboardIcon fontSize='small'></DashboardIcon>,
      route: "/Admins",
      subroute: "/Admins",
      component: <AdminTwo></AdminTwo>,
    }
  ],
  /*  [{
     parent: "Admins",
     type: "collapse",
     name: "HR dashboard",
     key: "/Admins/HR",
     icon: <DashboardIcon fontSize='small'></DashboardIcon>,
     route: "/Admins/HR",
     subroute: "/Admins/:subPath",
     component: <AdminTwo></AdminTwo>,
   },] */

  // [
  //   {
  //     parent: "Lunch",
  //     type: "collapse",
  //     name: "Lunch app",
  //     key: "lunch",
  //     icon: <RamenDiningIcon fontSize='small'></RamenDiningIcon>,
  //     route: "/User/lunch",
  //     subroute: "/User/:subPath",
  //     component: <Users />,
  //   },
  // ],
  // [
  //   {
  //     type: "divider",
  //   },
  // ],

  // [
  //   {
  //     parent: "Hours",
  //     type: "collapse",
  //     name: "Log",
  //     key: "logHours",
  //     icon: <AddIcon fontSize='small'></AddIcon>,
  //     route: "/User/hours/log",
  //     subroute: "/User/:subPath/:secondPath",
  //     component: <Users />,
  //   },
  //   {
  //     parent: "Hours",
  //     type: "collapse",
  //     name: "Overview",
  //     key: "See Hours",
  //     icon: (
  //       <AccountBalanceOutlinedIcon fontSize='small'></AccountBalanceOutlinedIcon>
  //     ),
  //     route: "/User/hours/overview",
  //     subroute: "/User/:subPath/:secondPath",
  //     component: <Users />,
  //   },
  // ],
  // [
  //   {
  //     type: "divider",
  //   },
  // ],
  // [
  //   // {
  //   //   parent: "Schedule",
  //   //   type: "collapse",
  //   //   name: "Log",
  //   //   key: "/Schedule/log",
  //   //   icon: <AddIcon fontSize='small'></AddIcon>,
  //   //   route: "/User/schedule/log",
  //   //   subroute: "/User/:subPath/:secondPath",
  //   //   component: <Users />,
  //   // },
  //   {
  //     parent: "Schedule",
  //     type: "collapse",
  //     name: "Schedule",
  //     key: "/Schedule",
  //     route: "/User/schedule",
  //     subroute: "/User/:subPath",
  //     icon: (
  //       <CalendarViewMonthOutlinedIcon fontSize='small'></CalendarViewMonthOutlinedIcon>
  //     ),
  //     component: <Users />,
  //   },
  //   {
  //     parent: "Schedule",
  //     type: "collapse",
  //     name: "Company",
  //     key: "/Schedule/D1",
  //     icon: (
  //       <CorporateFareOutlinedIcon fontSize='small'></CorporateFareOutlinedIcon>
  //     ),
  //     route: "/User/schedule/company",
  //     subroute: "/User/:subPath/:secondPath",
  //     component: <Users />,
  //   },
  // ],
  // [
  //   {
  //     type: "divider",
  //   },
  // ],

  // [
  //   {
  //     parent: "Admins",
  //     type: "collapse",
  //     name: "Dashboard",
  //     key: "/Admins",
  //     icon: <DashboardIcon fontSize='small'></DashboardIcon>,
  //     route: "/Admins",
  //     subroute: "/Admins",
  //     component: <AdminTwo></AdminTwo>,
  //   },
  //   // {
  //   //   type: "divider",
  //   // },
  //   // {
  //   //   parent: "Admins",
  //   //   type: "collapse",
  //   //   name: "Schedules",
  //   //   key: "/Admins/schedules/edit",
  //   //   icon: (
  //   //     <ModeEditOutlineOutlinedIcon fontSize='small'></ModeEditOutlineOutlinedIcon>
  //   //   ),
  //   //   route: "/Admins/schedules/edit",
  //   //   subroute: "/Admins/:subPath/:secondPath",
  //   //   component: <Admins />,
  //   // },
  //   // {
  //   //   parent: "Admins",
  //   //   type: "collapse",
  //   //   name: "Hours",
  //   //   key: "/Admins/hours",
  //   //   icon: (
  //   //     <ModeEditOutlineOutlinedIcon fontSize='small'></ModeEditOutlineOutlinedIcon>
  //   //   ),
  //   //   route: "/Admins/hours",
  //   //   subroute: "/Admins/:subPath",
  //   //   component: <Admins />,
  //   // },
  //   // {
  //   //   type: "divider",
  //   // },
  //   // {
  //   //   parent: "Admins",
  //   //   type: "collapse",
  //   //   name: "Salary overview",
  //   //   key: "/Admins/salary/overview",
  //   //   icon: (
  //   //     <PlaylistAddOutlinedIcon fontSize='small'>
  //   //       table_view
  //   //     </PlaylistAddOutlinedIcon>
  //   //   ),
  //   //   route: `/Admins/salary/overview`,
  //   //   subroute: "/Admins/:subPath/:secondPath",
  //   //   component: <Admins />,
  //   // },
  //   // {
  //   //   type: "divider",
  //   // },
  //   // {
  //   //   parent: "Admins",
  //   //   type: "collapse",
  //   //   name: "Assign departments",
  //   //   key: "/Admins/assignments/departments",
  //   //   icon: <TaskAltOutlinedIcon fontSize='small'></TaskAltOutlinedIcon>,
  //   //   route: "/Admins/assignments/departments",
  //   //   subroute: "/Admins/:subPath/:secondPath",
  //   //   component: <Admins />,
  //   // },
  //   // {
  //   //   parent: "Admins",
  //   //   type: "collapse",
  //   //   name: "Assign users",
  //   //   key: "/Admins/assignments/users",
  //   //   icon: <TaskAltOutlinedIcon fontSize='small'></TaskAltOutlinedIcon>,
  //   //   route: "/Admins/assignments/users",
  //   //   subroute: "/Admins/:subPath/:secondPath",
  //   //   component: <Admins />,
  //   // },
  //   {
  //     type: "divider",
  //   },
  // ],
];

export default routes;
