// React imports
import { useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";

// Moment import
import moment from "moment";

// Lodash import
import _ from "lodash";

// Context imports
import { GlobalState } from "context/store.js";

// MUI imports
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { Button, Card, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import CardHeader from "App/Views/Components/Material_ui/Card/CardHeader.js";
import CardActions from "@mui/material/CardActions";
import CloseIcon from "@mui/icons-material/Close";

// Custom components imports
import {
  Number,
  TextFieldAuto,
  DefaultBtn,
} from "App/Views/Components/Custom/MUIComponents";
import {
  ControlledRadioButtonsGroup
} from "App/Views/Components/Custom/CustomComponents";
import CardFooter from "App/Views/Components/Material_ui/Card/CardFooter";
import { DepartmentTreeFallback } from "App/Views/Components/Custom/DepartmentFallback";

// Services imports
import HoursDataService from "services/REST_service/hours.service";
import TokenService from "services/REST_service/token.service";
import AuthService from "services/REST_service/auth.service";

// Functions imports
import ConverterClass from "App/Functions/converterClass.js";
import useWindowSize from "App/Functions/useWindowSize";
import { Departments } from "App/Views/Emply";
import Functions from "App/Functions/functions";
import { usePosition } from "App/Functions/usePosition";
import CardBody from "App/Views/Components/Material_ui/Card/CardBody";



export default function LogHoursInterface(props) {

  const { latitude, longitude } = usePosition();
  const [Store, setStore] = useContext(GlobalState);
  const [DropDownInputs, setDropDownInputs] = useState({
    Team: TokenService?.getEmployeeData()?.departmentId,
    Project: "",
    Task: "",
    Type: "Normal",
  });
  const [selectedDate, setSelectedDateState] = useState(moment().format());
  const [projects, setProjects] = useState([]);
  const [Role] = useState();
  // END STATES
  //HOOKS
  const [location, setLocation] = useState("Home Office");


  const setGeoLocation = useCallback(() => {
    const area = Functions.distanceInKmBetweenEarthCoordinates(
      latitude,
      longitude
    ).toFixed(1);
    if (area < 2) {
      setLocation("Office");
    } else {
      setLocation("Home Office");
    }

  }, [latitude, longitude])

  useEffect(() => {
    setGeoLocation()
  }, [latitude, longitude]);

  const [disabledBtn, setDisabledBtn] = useState(false);

  const getTasksOptions = useCallback(async () => {
    const Team = DropDownInputs.Team;
    if (!Team) return;
    let Tasks = [];

    for (const e of Store.Task) {
      if (!e.Belongs_to) {
        e.Belongs_to = [];
      }
      if (e.Belongs_to.includes(Team)) {
        Tasks.push(e.Task);
      }
    }
    let Projects = [];
    for (const e of Store.Project) {
      if (!e.Belongs_to) {
        e.Belongs_to = [];
      }
      if (e.Belongs_to.includes(DropDownInputs.Team)) {
        Projects.push(e);
      }
    }
    setProjects(Projects);
    let setPr = "";
    if (Projects.length > 0) {
      setPr = Projects[0].Project;
    }
    setDropDownInputs((DropDownInputs) => ({
      ...DropDownInputs,
      Project: setPr,
    }));
    // if (Emply.User.User.id === TokenService.getUserID()) {
    //   setRole(Emply.User.User.jobTitle);
    //   if (Emply.User.User.jobTitle === "Konsultant") {
    //     setDropDownInputs((DropDownInputs) => ({
    //       ...DropDownInputs,
    //       Task: "Calling",
    //     }));
    //   } else {
    //     setDropDownInputs((DropDownInputs) => ({
    //       ...DropDownInputs,
    //       Task: Tasks[0],
    //     }));
    //   }
    // }
    // esilint disable next line
  }, [Store, DropDownInputs]);

  useEffect(() => {
    getTasksOptions();
    // eslint-disable-next-line
  }, [DropDownInputs.Team]);
  // END CALLBACKS
  // FUNCTIONS
  //? This function will return the values that are not numbers from the response data from Tasks, Projects and Types tables from the SQL query
  async function submitHours(e) {
    function setErrorNotification(notification) {
      setStore({
        Notification: {
          color: "error",
          icon: "warning",
          title: "Failed",
          content: notification,
          dateTime: moment(),
        },
      });
      return;
    }

    e.preventDefault();
    e.persist(e);
    const user = await AuthService.getCurrentUser();
    let elements = ConverterClass.formToJSON(e.target.elements);
    elements.Date = moment(elements.Date).format();
    const Date = moment(elements.Date).format();
    const Week_number = moment(Date).isoWeek();
    elements.Meetings = parseInt(elements.Meetings);
    elements.Contacts = parseInt(elements.Contacts);
    elements.Hours = parseFloat(elements.Hours);
    elements.Week_number = Week_number;
    elements.Date = Date;
    elements.User = user.UserName;
    elements.Email = user.UserEmail;
    elements.Account_Debit = DropDownInputs.Team;
    let EmployeeID = ''
    try {
      EmployeeID = TokenService.getEmployeeData().id
    } catch (error) {
      alert("Error: EmployeeID not found in Emply, please contact supervisor")
      return
    }
    elements.Account_Credit = EmployeeID;
    elements = _.merge(elements, DropDownInputs);
    elements.User_UUID = user.id;
    if (
      elements.Hours === null ||
      elements.Hours === undefined ||
      elements.Hours === "" ||
      elements.Hours === 0
    ) {
      setErrorNotification("Please input your hours");
      return;
    }
    if (elements.Project === null || elements.Project === undefined) {
      setErrorNotification("Please input your project");
      return;
    }
    if (elements.Task === null || elements.Task === undefined) {
      setErrorNotification("Please input your task");
      return;
    }
    //New feature where it alerts when the description is more than 225 characters (done by adriano)
    if (elements.Description === undefined) {
      elements.Description = "";
    }
    if (elements.Description.length >= 220) {
      setErrorNotification("Comment can't be more than 225 characters");
      return;
    }
    setDisabledBtn(true);
    HoursDataService.create(elements)
      .then(async (response) => {
        if (response.request.statusText === "OK") {
          setStore({
            Notification: {
              color: "success",
              icon: "check",
              title: "Success",
              content: response.data.message,
              dateTime: moment(),
            },
          });

          const Hours = await HoursDataService.RetrieveHours(
            Store.CurrentPeriod,
          );
          setStore({ Hours: Hours })
          setDisabledBtn(false);
          let Team = DropDownInputs.Team;
          let Project = DropDownInputs.Project;
          let Task = DropDownInputs.Task;
          setDropDownInputs((state) => ({
            ...state,
            Team: Team,
            Project: Project,
            Task: Task,
          }));
        }
      })
      .catch((e) => {
        let message = e.response.data.message;
        setStore({
          Notification: {
            color: "error",
            icon: "warning",
            title: "Failed",
            content:
              typeof message === "string" ? message : message.errors[0].message,
            dateTime: moment(),
          },
        });
        setDisabledBtn(false);
      });
  }
  function PropsFunc(data, placeholder) {
    setDropDownInputs((DropDownInputs) => ({
      ...DropDownInputs,
      [data.target.name]: data.target.value,
    }));
  }
  // END FUNCTIONS
  // RENDER
  let leng

  if (Store.Task?.length > 0) {
    leng = Store.Task.filter((e) => {
      if (!e.Belongs_to) {
        e.Belongs_to = [];
      }
      return e.Belongs_to.includes(DropDownInputs.Team);
    });
  }

  const size = useWindowSize();

  if (!Store.CurrentPeriod) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          pointerEvents: "none",
        }}
      >
        < Card
          style={{
            maxHeight: "100vh",
            overflow: "hidden",
            overflowY: "scroll",
            margin: "auto",
            width: size.width < 500 ? "100%" : "fit-content",
            pointerEvents: "all",
          }
          }
        >

          <CardBody >
            <Typography sx={{ padding: "1rem", fontSize: "1.5rem" }} variant="h2" component="h2">No Salary period is defined at the moment, please contact an Administrator as they can create new salary period.</Typography>
          </CardBody>
        </Card>
      </div>
    )
  } else {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          pointerEvents: "none",
        }}
      >
        <Card
          style={{
            maxHeight: "100vh",
            overflow: "hidden",
            overflowY: "scroll",
            margin: "auto",
            width: size.width < 768 ? "100%" : "fit-content",
            pointerEvents: "all",
          }}
        >
          <CardHeader>
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <IconButton
                onClick={() => props.handleClose()}
                color="error"
                aria-label="upload picture"
                component="span"
              >
                <CloseIcon size="medium" color="error" />
              </IconButton>
            </CardActions>
          </CardHeader>
          <form
            onSubmit={submitHours}
            style={{ height: "fit-content", width: "fit-content" }}
          >
            <Stack
              direction={{ xs: "column", lg: "row" }}
              justifyContent="space-around"
              alignItems="center"
              sx={{ width: { xs: "100vw", lg: "fit-content" }, marginX: { xs: "auto", lg: "2rem" }, marginBottom: "20px" }}
            >
              <Stack
                direction={{ xs: "column" }}
                alignItems="flex-start"
                sx={{ width: "100%" }}
              >
                <Stack direction="row" sx={{ marginX: { xs: "auto", lg: "0" } }}>
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={selectedDate}
                    maxDate={new Date(Store.CurrentPeriod.Date_end)}
                    minDate={new Date(Store.CurrentPeriod.Date_start)}
                    onChange={(newValue) => {
                      setSelectedDateState(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <input
                    type="hidden"
                    required
                    name={"Date"}
                    value={selectedDate}
                  />
                </Stack>
                <Stack
                  sx={{ width: { xs: "fit-content", lg: "100%" }, marginX: { xs: "auto", lg: "0" } }}
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {Store.Emply && (
                    <Departments PropsFunc={PropsFunc} name={"Team"} />
                  )}
                  {!Store.Emply && (
                    <DepartmentTreeFallback PropsFunc={PropsFunc} name={"Team"} />
                  )}
                  <input
                    type="hidden"
                    required
                    name={"Date"}
                    value={selectedDate}
                  />
                </Stack>


              </Stack>

              <Grid
                container
                sx={{ width: "100%", marginTop: "2rem", }}
                alignItems={size.width < 992 ? "center" : "flex-start"}
                justifyContent={size.width < 992 ? "center" : "flex-start"}
              >
                {/* Projects task and type */}

                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", lg: "flex-start" }
                  }}
                  xs={9} sm={7}
                >
                  <Stack direction="column" spacing={4} sx={{ width: "100%" }}>
                    <Box >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-labelf">
                          Select project
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-labelf"
                          id="demo-simple-select"
                          value={DropDownInputs.Project}
                          label="Project"
                          name={"Project"}
                          onChange={PropsFunc}

                        >
                          {projects.map((e, index) => {
                            return (
                              <MenuItem key={index} value={e.Project}>
                                {e.Project}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-labels">
                          Select Task
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-labels"
                          id="demo-simple-select"
                          value={DropDownInputs?.Task}
                          label="Task"
                          name={"Task"}
                          onChange={PropsFunc}
                        >

                          <MenuItem value={"Calling"}>Calling</MenuItem>

                          {Store.Task.map((e) => {
                            if (!e.Belongs_to) {
                              e.Belongs_to = [];
                            }
                            if (e.Belongs_to.includes(DropDownInputs.Team)) {
                              return (
                                <MenuItem key={e.Task} value={e.Task}>
                                  {e.Task}
                                </MenuItem>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </Select>
                      </FormControl>
                    </Box>

                    <Box>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-labels">
                          Select Type (supplement)
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-labels"
                          id="demo-simple-select"
                          value={DropDownInputs.Type}
                          label="Type"
                          name={"Type"}
                          onChange={PropsFunc}
                        >
                          {Store.Type.map((e) => {
                            return (
                              <MenuItem key={e.Type} value={e.Type}>
                                {e.Type}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Stack>
                </Grid>

                {/* Hours and contacts and meetings sold */}

                <Grid item sx={{ marginTop: "2rem" }} xs={9} sm={7}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    style={{ marginTop: "2rem", width: "100%" }}
                  >
                    <Number
                      IconID={"Clock"}
                      InputName={"Hours"}
                      label={"Hours"}
                      required={true}
                      step={0.25}
                      maximum={16}
                      minimum={0}
                      clear={disabledBtn}
                      PassedStyle={{
                        minWidth:
                          size.width < 1450 && size.width > 1400 ? "90%" : "8rem",
                        width: "100%"
                      }}
                    ></Number>

                    {DropDownInputs.Task === "Calling" && (
                      <>
                        <Number
                          IconID={"Phone"}
                          InputName={"Contacts"}
                          label={"Contacts"}
                          required={false}
                          step={1}
                          maximum={250}
                          minimum={0}
                          clear={disabledBtn}
                          PassedStyle={{
                            minWidth:
                              size.width < 1450 && size.width > 1400 ? "90%" : "8rem",
                            width: "100%"
                          }}
                        ></Number>
                        <Number
                          IconID={"PeopleAdd"}
                          InputName={"Meetings"}
                          label={"Meetings"}
                          placeHolder={"Meetings"}
                          required={false}
                          step={1}
                          maximum={250}
                          minimum={0}
                          clear={disabledBtn}
                          PassedStyle={{
                            minWidth:
                              size.width < 1450 && size.width > 1400 ? "90%" : "8rem",
                            width: "100%"
                          }}
                        ></Number>
                      </>
                    )}
                  </Stack>
                </Grid>

                { /* Location and comment */}
                <Grid item xs={9} sm={7} lg={9}>
                  <ControlledRadioButtonsGroup
                    currentState={location}
                    officeClicked={"officeClicked"}
                    where={'LogHours'}
                  />
                  <TextFieldAuto
                    InputName={"Description"}
                    label={"Comment"}
                    required={false}
                    placeHolder={"Optionally add a comment to the input"}
                    clear={disabledBtn}
                  ></TextFieldAuto>
                </Grid>
              </Grid>
            </Stack>
            <CardFooter>
              <Box sx={{ display: "flex", flexDirection: size.width < 450 ? "column" : "row", justifyContent: 'space-around', width: "90%", marginX: "auto" }}>
                <div style={{ marginBottom: "1rem", marginTop: "1rem", width: size.width < 450 ? "100%" : "40%" }}>
                  <DefaultBtn
                    text={"Log hours"}
                    disabled={disabledBtn}
                    BtnType={"submit"}
                  ></DefaultBtn>
                </div>
                <div style={{ marginBottom: "1rem", marginTop: "1rem", marginRight: "5px", width: size.width < 450 ? "100%" : "40%" }}>
                  <Button variant="contained" color="error" type="reset" sx={{ width: "100%" }} onClick={() => props.handleClose()}>
                    Cancel
                  </Button>
                </div>
              </Box>
            </CardFooter>
          </form>
        </Card>
      </div>
    );
  }
}