// React
import { useState, useEffect, useCallback, useContext } from "react";
import moment from 'moment-timezone'
import useWindowSize from "App/Functions/useWindowSize";

// Material UI
import { Box, Button, CircularProgress, Divider, Icon, Modal } from "@mui/material";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CardBody from "App/Views/Components/Material_ui/Card/CardBody.js";
import GridItem from "App/Views/Components/Material_ui/Grid/GridItem.js";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import GridContainer from "App/Views/Components/Material_ui/Grid/GridContainer.js";
import { Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";


// App Components
import ScheduleDataService from "services/REST_service/schedule.service";
import { GlobalState } from "context/store.js";
import ChooseTableModal from "App/Views/Components/Modals/chooseTable";

import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import BeachAccessOutlinedIcon from '@mui/icons-material/BeachAccessOutlined';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';

export default function MonthlyScheduleOffice(props) {
  const [Store, setStore] = useContext(GlobalState);
  const size = useWindowSize();
  const [state, setState] = useState();
  const [month, setMonth] = useState(moment());
  const [monthlyHours, setMonthlyHours] = useState(0);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [workweek, setWorkweek] = useState(true);
  const [WorkData, SetWorkData] = useState();
  const [times, setTimes] = useState({});
  const [value, setValue] = useState(moment());
  const handleOpen = useCallback(
    async (data) => {
      SetWorkData(data);
      setOpen(true);
    },
    [SetWorkData]
  );
  const handleOpen2 = useCallback(
    async (data) => {

      // const object = {
      //   BookedFrom: Date,
      //   BookedTo: Date,
      //   Email: String,
      //   Table: String,
      //   Table_number: Number,
      //   User: String,
      //   date: Date,
      //   email: String,
      // }
      // let booked = []
      // for (const e of data.array) {
      //   console.log(e)
      //   const Booking = { ...object }
      //   Booking.BookedFrom = e.Date_start_time
      //   Booking.BookedTo = e.Date_end_time
      //   Booking.Email = e.Email
      //   Booking.Table = e.Table
      //   Booking.Table_number = e.Table_number
      //   Booking.User = e.User
      //   Booking.date = e.Date_end_time
      //   Booking.email = e.email
      //   booked.push(Booking)
      // }
      setTimes({
        start: moment(data.array[0].Date_start_time).startOf('day'),
        end: moment(data.array[0].Date_end_time).endOf('day'),
      })
      setValue(moment(data.array[0].Date_start_time).startOf('day'))
      SetWorkData(data);
      setOpen2(true);
    },
    [SetWorkData]
  );



  const getSchedule = useCallback(async () => {
    const param = {
      start: moment(month).startOf("month").startOf('day'),
      end: moment(month).endOf("month").endOf('day'),
    }
    let Calendar = Store.Schedules.UserCalendarMonth

    const Schedule = await ScheduleDataService.ExportReport(param);
    const FirstDay = Store.Schedules.UserCalendarMonth[0].days[0]
    let hours = 0;
    Schedule.forEach((element) => {
      if (moment(element.Date_start_time).isSame(moment.utc(FirstDay).add(7, 'day'), 'month')) {
        hours += parseFloat(element.Hours);
      }
    })
    setMonthlyHours(hours);
    let RowChildren = [];
    let WeekNumber = [];
    // eslint-disable-next-line
    let WeeKrows = 0;
    let WeekRows = [];
    if (workweek === true) {
      // eslint-disable-next-line
      for (const [index, e] of Calendar.entries()) {
        let days = []
        for (const day of e.days) {
          if (day.isoWeekday() !== 6 && day.isoWeekday() !== 7) {
            days.push(day)
          }
        }
        WeekRows.push({ days: days })
      }
    }
    if (WeekRows.length > 0) {
      Calendar = WeekRows;
    }
    Calendar.map((e) => {
      WeeKrows++;
      const WeekNumbTD = (
        <td className="CalendarBox WeekNumber">
          {moment(e.days[0]).isoWeek()}
        </td>
      );
      WeekNumber.push(WeekNumbTD);
      let Total = {
        AtOfficeWeek: 0,
        AtHomeWeek: 0,
        AtVacationWeek: 0,
        AtBusinessTripWeek: 0,
        props: {
          week: 0
        }
      }
      const week = e.days.map((t, index) => {
        //? double map since Calendar = [{object={day1, ...},{object={day1, ...}}]
        let WorkData = {
          SameMonth: false,
          isSameDay: false,
          AtOffice: 0,
          AtHome: 0,
          AtVacation: 0,
          AtBusinessTrip: 0,
          array: [],
        };
        Schedule.forEach((e) => {
          if (moment(e.Date_start_time).isSame(t, "day")) {
            WorkData.array.push(e);
            if (e.Location === "Office") {
              WorkData.AtOffice += 1
              Total.AtOfficeWeek += 1
            } else if (e.Location === "Home Office") {
              WorkData.AtHome += 1
              Total.AtHomeWeek += 1
            } else if (e.Location === "Vacation") {
              WorkData.AtVacation += 1
              Total.AtVacationWeek += 1
            } else if (e.Location === "Business Trip") {
              WorkData.AtBusinessTrip += 1
              Total.AtBusinessTripWeek += 1
            } else if (e.Location === "Office reserved") {
              WorkData.AtOffice += 1
              Total.AtOfficeWeek += 1
            }
          }
        });
        if (moment(t).isSame(moment(), "day")) {
          WorkData.isSameDay = true;
        }
        //? Get the second week and check if the first day of that
        if (Calendar[2].days[0].isSame(t, "month")) {
          WorkData.SameMonth = true;
        }
        Total.props.week = moment(t).isoWeek()

        return (
          <td
            onClick={() => handleOpen2(WorkData)}
            key={index}
            week={moment(t).isoWeek()}
            isbeforetoday='false'
            issamemonth={`${WorkData.SameMonth}`}
            issameday={`${WorkData.isSameDay}`}
            targettoclick={moment(t).format()}
          >
            {WeeKrows === 1 ? (
              <>
                <Typography>
                  {moment(t).format("ddd")}
                </Typography>
              </>
            ) : null}
            <Typography sx={{ marginBottom: "0.7rem" }}>
              {moment(t).format("DD")}
            </Typography>

            <div className="DateInfoSchedule howManyPeopleOnScheduleIcons" >
              <Stack className="amountINOffice" sx={{ padding: { lg: '1rem' } }}>
                {WorkData.SameMonth &&
                  <>
                    <Stack direction={'row'} flexWrap='wrap' alignContent='center' justifyContent='space-evenly' justifyItems='center' alignItems='center' >
                      {WorkData.AtOffice > 0 &&
                        <Stack sx={{ width: "45%" }} direction={'column'} alignContent='center' justifyContent='center' alignItems='center' >
                          <Stack direction={'row'} alignContent='baseline' >
                            <BusinessIcon fontSize="20px" sx={{ fontSize: "20px", marginRight: "0.5rem" }} color="primary" />
                            <Typography variant="h2" component="h2" sx={{ fontWeight: 700, fontSize: "20px" }} gutterBottom>{" " + WorkData.AtOffice}</Typography>
                          </Stack>
                          <Typography variant="h2" component="h2" sx={{ fontSize: "20px", marginRight: "0.5rem" }} gutterBottom>Office</Typography>
                        </Stack>
                      }
                      {WorkData.AtHome > 0 &&
                        <Stack sx={{ width: "45%" }} direction={'column'} alignContent='center' justifyContent='center' alignItems='center' >
                          <Stack direction={'row'} >
                            <HomeWorkIcon fontSize="20px" sx={{ fontSize: "20px", marginRight: "0.5rem" }} color="primary" />
                            <Typography variant="h2" component="h2" sx={{ fontWeight: 700, fontSize: "20px" }} gutterBottom>{" " + WorkData.AtHome}</Typography>
                          </Stack>
                          <Typography variant="h2" component="h2" sx={{ fontSize: "20px", marginRight: "0.5rem" }} gutterBottom>Home Office</Typography>
                        </Stack>
                      }
                    </Stack>
                  </>
                }
              </Stack>
            </div>
          </td>
        );
      });
      //? Push each week as object to weekWithCalBoxes array
      const total = (
        <td className="CalendarBox WeekNumber">
          {WeeKrows === 1 ? (
            <>
              <Typography variant="h2" component="h2" sx={{ fontWeight: 700, fontSize: "20px" }} gutterBottom>Total</Typography>
            </>
          ) : null}
          <Stack sx={{ padding: "1rem" }} direction={'column'} >
            {Total.AtOfficeWeek > 0 && (
              <Stack direction={'row'} >
                <BusinessIcon fontSize="20px" sx={{ fontSize: "20px", marginRight: "0.5rem" }} color="primary" />
                <Typography variant="h2" component="h2" sx={{ fontWeight: 700, fontSize: "20px" }} gutterBottom>{" " + Total.AtOfficeWeek}</Typography>
              </Stack>
            )}
            {Total.AtHomeWeek > 0 && (
              <Stack direction={'row'} >
                <HomeWorkIcon fontSize="20px" sx={{ fontSize: "20px", marginRight: "0.5rem" }} color="primary" />
                <Typography variant="h2" component="h2" sx={{ fontWeight: 700, fontSize: "20px" }} gutterBottom>{" " + Total.AtHomeWeek}</Typography>
              </Stack>
            )}
            {Total.AtVacationWeek > 0 && (
              <Stack direction={'row'} >
                <BeachAccessIcon fontSize="20px" sx={{ fontSize: "20px", marginRight: "0.5rem" }} color="primary" />
                <Typography variant="h2" component="h2" sx={{ fontWeight: 700, fontSize: "20px" }} gutterBottom>{" " + Total.AtVacationWeek}</Typography>
              </Stack>
            )}
            {Total.AtBusinessTripWeek > 0 && (
              <Stack direction={'row'} >
                <WorkOutlineOutlinedIcon fontSize="20px" sx={{ fontSize: "20px", marginRight: "0.5rem" }} color="primary" />
                <Typography variant="h2" component="h2" sx={{ fontWeight: 700, fontSize: "20px" }} gutterBottom>{" " + Total.AtBusinessTripWeek}</Typography>
              </Stack>
            )}
          </Stack>
        </td>
      );
      week.push(total)
      return RowChildren.push(week);

      //? end result week = [{<div>day1</div>, <di....},{<div>day1</div>, <di....},{<div>day1</div>, <di....}]
    });
    //? now make containers for the weeks, as many containers as weeks
    //? Make rows
    const height = 50 / WeekNumber.length
    let rows = RowChildren.map((e, index) => {
      let indexOfTotal = e.length - 1
      return (
        <tr
          style={{ maxHeight: height + "vh", overflow: "hidden" }}
          key={`weekNumber ${e[0].props.week}`}
          className={`weekNumber weekRow ${e[0].props.week}`}
        >
          {WeekNumber[index]}
          {e}

        </tr>
      );
    });
    setState(rows);
  }, [size, handleOpen2, Store, workweek, month]);

  useEffect(() => {
    if (Store.Schedules) {
      getSchedule();
    }
    // eslint-disable-next-line
  }, [workweek, Store.Schedules]);


  const LoadingAppIndicator = ({ status }) => {
    return (
      <Box sx={{ display: 'flex', width: "98vw", height: "98vh" }}>
        <Box sx={{ margin: "auto", display: "flex", flexDirection: "column", alignContent: "center", alignItems: 'center' }}>
          <CircularProgress sx={{ margin: "2rem" }} />
          <Typography >{status}</Typography>
        </Box>
      </Box>
    );
  }
  const handleClose2 = () => setOpen2(false);

  const OnclickCallback = useCallback(async (e) => {
    setState();
    if (e === "Forwards") {
      let PlusMonth = moment(month).add(1, "M");
      const Schedules = await ScheduleDataService.RetrieveSchedules(Store.CurrentPeriod, PlusMonth);
      setStore({ Schedules })
      setMonth(PlusMonth);
    } else if (e === "Back") {
      let MinusMonth = moment(month).subtract(1, "M");
      setMonth(MinusMonth);
      const Schedules = await ScheduleDataService.RetrieveSchedules(Store.CurrentPeriod, MinusMonth);
      setStore({ Schedules })
    } else if (e === "Today") {
      let ThisMonth = moment();
      setMonth(ThisMonth);
      const Schedules = await ScheduleDataService.RetrieveSchedules(Store.CurrentPeriod, ThisMonth);
      setStore({ Schedules })
    }
  }, [Store.CurrentPeriod, month, setStore])

  return (
    <div
      style={{
        display: "flex",
        pointerEvents: "none",
        height: size.width < 500 ? "100vh" : "100%",
        width: size.width < 500 ? "100%" : "100%",
      }}
    >

      <GridContainer style={{ padding: 0, width: "100%", height: "100%", pointerEvents: "all" }}>
        <GridItem style={{ padding: 0 }} xs={12} sm={12} md={12}>
          <Card style={{ width: "100%", height: "100%", margin: 0 }}>
            <CardHeader style={{ paddingBottom: "0.1rem" }}
              title={
                moment(month).format("MMMM YYYY")
              }
              subheader={
                <p>{monthlyHours} hours scheduled for the calendar month</p>
              }
              action={
                !props?.Dashboard ? (
                  <IconButton
                    onClick={() => props.handleClose()}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <CloseIcon size="medium" color="error" />
                  </IconButton>
                ) :
                  (
                    <Stack direction='row' style={{ marginBottom: "0.5rem" }}>
                      <Button
                        onClick={() => OnclickCallback("Back")}
                        variant="text"
                        startIcon={<ArrowBackIosNew />}
                      >Previous month</Button>
                      <Button
                        onClick={() => OnclickCallback("Forwards")}
                        variant="text"
                        endIcon={<ArrowForwardIosIcon />}
                      >Next month</Button>
                      <Button variant="text" color='error' onClick={(e) => setWorkweek(!workweek)}>
                        {workweek ? "Full week" : "Work week"}
                      </Button>
                    </Stack>
                  )
              }
            />
            {!props?.Dashboard && (
              <CardHeader style={{ paddingBottom: "0.1rem" }}
                subheader={
                  <>
                    <Stack direction='row' style={{ marginBottom: "0.5rem" }}>
                      <Button
                        onClick={() => OnclickCallback("Back")}
                        variant="text"
                        startIcon={<ArrowBackIosNew />}
                      >Previous month</Button>
                      <Button
                        onClick={() => OnclickCallback("Today")}
                        variant="contained"
                      >
                        Current month
                      </Button>
                      <Button
                        onClick={() => OnclickCallback("Forwards")}
                        variant="text"
                        endIcon={<ArrowForwardIosIcon />}
                      >Next month</Button>
                      <Button variant="text" color='error' onClick={(e) => setWorkweek(!workweek)}>
                        {workweek ? "View full week" : "View work week"}
                      </Button>
                    </Stack>
                  </>
                }
              />
            )}

            <CardBody style={{ paddingRight: 0, paddingLeft: 0 }}>
              {state &&
                < table
                  style={{ height: "100%", width: "100%" }}
                  className="CalendarMonth customCalendar"
                >
                  <tbody style={{ height: "100%" }}>{state}</tbody>
                </table>
              }
              {!state &&
                <LoadingAppIndicator status={"Loading schedule..."} />
              }

            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        sx={{ maxHeight: "100vh", overflow: "hidden", padding: "0.5rem" }}
        aria-describedby="modal-modal-description"
      >
        <ChooseTableModal
          times={times}
          handleModal={handleClose2}
          selectedDate={value}
          showAll={true}
        ></ChooseTableModal>
      </Modal>
    </div>
  );
}