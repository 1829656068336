// React
import { useState, useEffect, useCallback, useContext } from "react";
import moment from 'moment-timezone'
import useWindowSize from "App/Functions/useWindowSize";

// Material UI
import { Button, Modal } from "@mui/material";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CardBody from "App/Views/Components/Material_ui/Card/CardBody.js";
import GridItem from "App/Views/Components/Material_ui/Grid/GridItem.js";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import GridContainer from "App/Views/Components/Material_ui/Grid/GridContainer.js";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import BeachAccessOutlinedIcon from '@mui/icons-material/BeachAccessOutlined';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import { useMediaQuery } from "@material-ui/core";


// App Components
import ScheduleAdd from "../Modals/schedule_add";
import ScheduleEdit from "../Modals/schedule_edit/schedule_edit";
import ConverterClass from "App/Functions/converterClass.js";
import ScheduleDataService from "services/REST_service/schedule.service";
import TokenService from "services/REST_service/token.service";
import DownloadICS from "../Modals/download_schedule";
import { GlobalState } from "context/store.js";
import { HoursDataService } from "services";

const bgColorForSchedule = { Office: "#674AFE", 'Home Office': "#D351E7", 'Vacation': "#ff6361", "Business Trip": "#003f5c", "Office reserved": "#4eb1cb" }


export default function YourSchedule(props) {
  const [Store, setStore] = useContext(GlobalState);
  const size = useWindowSize();
  const [state, setState] = useState();
  const [month, setMonth] = useState(moment());
  const [monthlyHours, setMonthlyHours] = useState(0);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [workweek, setWorkweek] = useState(true);
  const [WorkData, SetWorkData] = useState();

  const handleOpen = useCallback(
    async (data) => {
      SetWorkData(data);
      setOpen(true);
    },
    [SetWorkData]
  );
  const handleOpen2 = useCallback(
    async (data) => {
      SetWorkData(data);
      setOpen2(true);
    },
    [SetWorkData]
  );

  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);
  const handleClose3 = () => setOpen3(false);
  const handleOpen3 = () => setOpen3(true);


  const getSchedule = useCallback(async () => {


    const Schedule = Store.Schedules.UserScheduledInCalendarMonth
    let Calendar = Store.Schedules.UserCalendarMonth
    const FirstDay = Store.Schedules.UserCalendarMonth[0].days[0]
    let hours = 0;
    Schedule.forEach((element) => {
      if (moment(element.Date_start_time).isSame(moment.utc(FirstDay).add(7, 'day'), 'month')) {
        hours += parseFloat(element.Hours);
      }
    })
    setMonthlyHours(hours);
    let RowChildren = [];
    let WeekNumber = [];
    // eslint-disable-next-line
    let WeeKrows = 0;
    let WeekRows = [];
    if (workweek === true) {
      // eslint-disable-next-line
      for (const [index, e] of Calendar.entries()) {
        let days = []
        for (const day of e.days) {
          if (day.isoWeekday() !== 6 && day.isoWeekday() !== 7) {
            days.push(day)
          }
        }
        WeekRows.push({ days: days })
      }
    }
    if (WeekRows.length > 0) {
      Calendar = WeekRows;
    }
    Calendar.map((e) => {
      WeeKrows++;
      const WeekNumbTD = (
        <td className="CalendarBox WeekNumber">
          {moment(e.days[0]).isoWeek()}
        </td>
      );
      WeekNumber.push(WeekNumbTD);
      const week = e.days.map((t, index) => {
        //? double map since Calendar = [{object={day1, ...},{object={day1, ...}}]
        let WorkData = {
          isWorking: false,
          SameMonth: false,
          isSameDay: false,
        };
        Schedule.forEach((e) => {
          if (moment(e.Date_start_time).isSame(t, "day")) {
            WorkData.isWorking = true;
            WorkData.Start = moment(e.Date_start_time).format("HH:mm");
            WorkData.End = moment(e.Date_end_time).format("HH:mm");
            WorkData.Hours = e.Hours;
            WorkData.Location = e.Location;
            WorkData.ID = e.Transaction_ID;
            WorkData.Original = {
              Start: e.Date_start_time,
              End: e.Date_end_time,
            };
            WorkData.Table_number = e.Table_number;
            WorkData.Team = e.Team;
          }
        });
        if (moment(t).isSame(moment(), "day")) {
          WorkData.isSameDay = true;
        }
        //? Get the second week and check if the first day of that
        if (Calendar[2].days[0].isSame(t, "month")) {
          WorkData.SameMonth = true;
        }
        return (
          <td
            onClick={() =>
              WorkData.isWorking !== false
                ? handleOpen2(WorkData)
                : handleOpen({ date: moment(t) })
            }
            key={index}
            week={moment(t).isoWeek()}
            isbeforetoday={`${moment(new Date()).isAfter(t)}`}
            issamemonth={`${WorkData.SameMonth}`}
            issameday={`${WorkData.isSameDay}`}
            className={
              WorkData.isWorking !== false
                ? `Working CalendarBox`
                : "CalendarBox notWorking"
            }
            targettoclick={moment(t).format()}
          >
            {WeeKrows === 1 ? (
              <>
                <Typography>
                  {moment(t).format("ddd")}
                </Typography>
              </>
            ) : null}
            <Typography sx={{ marginBottom: "0.7rem" }}>
              {moment(t).format("DD")}
            </Typography>

            {WorkData.isWorking !== false ? (
              
              <div className="DateInfoSchedule">
                <div className="ShowBigData" >
                  <List style={{ display: "flex" }}>
                    <ListItem style={{ margin: "auto", width: "fit-content", minWidth: "50%" }}>
                      <ListItemAvatar style={{ minWidth: props?.Dashboard && "unset" }} children={
                        <Avatar sx={{ bgcolor: bgColorForSchedule[WorkData.Location], width: props?.Dashboard ? 30 : 50, height: props?.Dashboard ? 30 : 50 }}>
                          {WorkData.Location === "Office" && (
                            <BusinessOutlinedIcon color={'white'} fontSize={props?.Dashboard ? "small" : "large"} />
                          )}
                          {WorkData.Location === "Home Office" && (
                            <HomeWorkOutlinedIcon color={'white'} fontSize={props?.Dashboard ? "small" : "large"} />
                          )}
                          {WorkData.Location === "Vacation" && (
                            <BeachAccessOutlinedIcon color={'white'} fontSize={props?.Dashboard ? "small" : "large"} />
                          )}
                          {WorkData.Location === "Business Trip" && (
                            <WorkOutlineOutlinedIcon color={'white'} fontSize={props?.Dashboard ? "small" : "large"} />
                          )}
                          {WorkData.Location === "Office reserved" && (
                            <BusinessOutlinedIcon color={'white'} fontSize={props?.Dashboard ? "small" : "large"} />
                          )}
                        </Avatar>
                      }>
                      </ListItemAvatar>
                      <Stack direction="column" sx={{ paddingLeft: "5px" }}>
                        {props?.Dashboard ? null :
                          <ListItemText
                            sx={{ fontSize: "1.2em" }}
                            primary={WorkData.Location}
                          />
                        }
                        <ListItemText
                          sx={{ fontSize: "1em" }}
                          primary={WorkData.Start + " - " + WorkData.End}
                          secondary={WorkData.Hours + " Hours"}
                        />
                      </Stack>
                    </ListItem>
                  </List>
                </div>
                <Stack
                  direction="column"
                  alignContent="center"
                  justifyContent="center"
                  alignItems="center"
                  justifyItems={"center"}
                  id="ShowSmallData"
                  sx={{ height: "100%" }}
                >
                  {WorkData.Location === "Office" && (
                    <BusinessIcon fontSize="large" />
                  )}
                  {WorkData.Location === "Home Office" && (
                    <HomeWorkIcon fontSize="large" />
                  )}
                  {WorkData.Location === "Vacation" && (
                    <BeachAccessIcon fontSize="large" />
                  )}
                  {WorkData.Location === "Business Trip" && (
                    <WorkOutlineOutlinedIcon fontSize="large" />
                  )}
                  {WorkData.Location === "Office reserved" && (
                    <BusinessOutlinedIcon fontSize="large" />
                  )}
                  <div style={{ fontWeight: 500, fontSize: "13px" }}>{WorkData.Location === "Office reserved" ? "Office" : WorkData.Location}</div>
                  {WorkData.Table_number && (
                    <span style={{ fontWeight: 400, fontSize: "11px" }}>
                      Table {WorkData.Table_number ?? ""}
                    </span>
                  )}
                </Stack>
              </div>
            ) : (
              ""
            )
            }
          </td>
        );
      });
      //? Push each week as object to weekWithCalBoxes array
      return RowChildren.push(week);
      //? end result week = [{<div>day1</div>, <di....},{<div>day1</div>, <di....},{<div>day1</div>, <di....}]
    });
    //? now make containers for the weeks, as many containers as weeks
    //? Make rows
    const height = 50 / WeekNumber.length
    let rows = RowChildren.map((e, index) => {

      return (
        <tr
          style={{ maxHeight: height + "vh", overflow: "hidden" }}
          key={`weekNumber ${e[0].props.week}`}
          className={`weekNumber weekRow ${e[0].props.week}`}
        >
          {size.width > 500 && (
            <>
              {WeekNumber[index]}
            </>)}
          {e}
        </tr>
      );
    });
    setState(rows);
  }, [size, handleOpen, handleOpen2, Store, workweek, props]);

  useEffect(() => {
    if (Store.Schedules) {
      getSchedule();
    }
    // eslint-disable-next-line
  }, [workweek, Store.Schedules]);



  const OnclickCallback = useCallback(async (e) => {
    if (e === "Forwards") {
      let PlusMonth = moment(month).add(1, "M");
      const Schedules = await ScheduleDataService.RetrieveSchedules(Store.CurrentPeriod, PlusMonth);
      const Hours = await HoursDataService.RetrieveHours(Store.CurrentPeriod, PlusMonth);
      setStore({ Schedules })
      setStore({ Hours: Hours })
      setMonth(PlusMonth);
    } else if (e === "Back") {
      let MinusMonth = moment(month).subtract(1, "M");
      setMonth(MinusMonth);
      const Schedules = await ScheduleDataService.RetrieveSchedules(Store.CurrentPeriod, MinusMonth);
      const Hours = await HoursDataService.RetrieveHours(Store.CurrentPeriod, MinusMonth);
      setStore({ Hours: Hours })
      setStore({ Schedules })
    } else if (e === "Today") {
      let ThisMonth = moment();
      setMonth(ThisMonth);
      const Schedules = await ScheduleDataService.RetrieveSchedules(Store.CurrentPeriod, ThisMonth);
      const Hours = await HoursDataService.RetrieveHours(Store.CurrentPeriod, ThisMonth);
      setStore({ Hours: Hours })
      setStore({ Schedules })
    }
  }, [Store.CurrentPeriod, month, setStore])

  return (
    <Stack
      sx={{
        display: "flex",
        pointerEvents: "none",
        height: size.width < 500 ? "100vh" : "100%",
        width: size.width < 500 ? "100%" : "100%",
        overflow: "scroll"
      }}
    >

      <GridContainer style={{ padding: 0, width: "100%", height: "100%", pointerEvents: "all" }}>
        <GridItem style={{ padding: 0 }} xs={12} sm={12} md={12}>
          <Card style={{ width: "100%", height: "100%", margin: 0 }}>
            <CardHeader style={{ paddingBottom: "0.1rem" }}
              title={
                moment(month).format("MMMM YYYY")
              }
              subheader={
                <p>
                  {useMediaQuery('(max-width:500px)')
                    ? `${monthlyHours} hours scheduled`
                    : `${monthlyHours} hours scheduled for the calendar month`}
                </p>
              }
              action={
                !props?.Dashboard ? (
                  <IconButton
                    onClick={() => props.handleClose()}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <CloseIcon size="medium" color="error" />
                  </IconButton>
                ) :
                  (
                    <Stack direction='row' style={{ marginBottom: "0.5rem" }}>
                      <Button
                        onClick={() => OnclickCallback("Back")}
                        variant="text"
                        startIcon={<ArrowBackIosNew />}
                      >Previous month</Button>
                      <Button
                        onClick={() => OnclickCallback("Forwards")}
                        variant="text"
                        endIcon={<ArrowForwardIosIcon />}
                      >Next month</Button>
                      <Button variant="text" color='error' onClick={(e) => setWorkweek(!workweek)}>
                        {workweek ? "Full week" : "Work week"}
                      </Button>
                    </Stack>
                  )
              }
            />
            {!props?.Dashboard && (
              <CardHeader style={{ paddingBottom: "0.1rem" }}
                subheader={
                  <>
                    <Stack direction={{ xs: "column", lg: "row" }} flexWrap="wrap" spacing={1} style={{ marginBottom: "0.5rem" }} alignContent={{ xs: "center", lg: "start" }}>
                      <Stack direction="row" flexWrap="wrap" justifyContent="center">
                        <IconButton onClick={() => OnclickCallback("Back")} size="small" sx={{ display: { xs: "flex", sm: "none" }, marginRight: "2rem" }}><ArrowBackIosNew /></IconButton>
                        <Button
                          sx={{ display: { xs: "none", sm: "flex" } }}
                          onClick={() => OnclickCallback("Back")}
                          variant="text"
                          startIcon={<ArrowBackIosNew />}
                        >Previous month</Button>
                        <Button
                          onClick={() => OnclickCallback("Today")}
                          variant="contained"
                        >
                          Current month
                        </Button>
                        <IconButton onClick={() => OnclickCallback("Forwards")} size="small" sx={{ display: { xs: "flex", sm: "none" }, marginLeft: "2rem" }}><ArrowForwardIosIcon /></IconButton>
                        <Button
                          onClick={() => OnclickCallback("Forwards")}
                          sx={{ display: { xs: "none", sm: "flex" } }}
                          variant="text"
                          endIcon={<ArrowForwardIosIcon />}
                        >Next month</Button>
                      </Stack>
                      <Stack direction="row" flexGrow={1} justifyContent={{ xs: "center", lg: "space-between" }}>
                        <Button variant="text" color='error' onClick={(e) => setWorkweek(!workweek)}>
                          {workweek ? "View full week" : "View work week"}
                        </Button>

                        <Button
                          onClick={handleOpen3}
                          variant="text"
                          endIcon={<FileDownloadOutlinedIcon />}
                          sx={{
                            marginLeft: { xs: "0", lg: "auto" },
                            marginRight: { xs: "0", lg: "2rem" },
                            cursor: "pointer",
                          }}
                        >Download ICS file</Button>
                      </Stack>
                    </Stack>
                  </>
                }
              />
            )}
            <CardBody style={{ paddingRight: 0, paddingLeft: 0 }}>
              <table
                style={{ height: "100%", width: "100%" }}
                className="CalendarMonth customCalendar"
              >
                <tbody style={{ height: "100%" }}>{state}</tbody>
              </table>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                sx={{ maxHeight: "100vh", padding: "0.5rem" }}
                aria-describedby="modal-modal-description"
              >
                <ScheduleAdd
                  handleClose={handleClose}
                  WorkData={WorkData}
                ></ScheduleAdd>
              </Modal>
              <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                sx={{ maxHeight: "100vh", overflow: "hidden", padding: "0.5rem" }}
                aria-describedby="modal-modal-description"
              >
                <ScheduleEdit
                  handleClose={handleClose2}
                  WorkData={WorkData}
                ></ScheduleEdit>
              </Modal>

              <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                sx={{ maxHeight: "100vh", overflow: "scroll", padding: "0.5rem" }}
                aria-describedby="modal-modal-description"
              >
                <DownloadICS handleClose={handleClose3}></DownloadICS>
              </Modal>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Stack>
  );
}