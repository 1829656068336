import "simplebar/dist/simplebar.min.css";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardFooter from "App/Views/Components/Material_ui/Card/CardFooter";
import { useCallback } from "react";
import { Alert, Button, Link, Paper, Stack } from "@mui/material";
import { ScheduleDataService } from "services";
import { TokenService } from "services";
import * as FileSaver from "file-saver";
import moment from "moment-timezone";

let ics = require("ics");

export default function DownloadICS(props) {
  const createAndDownload = useCallback(async (which) => {
    const start =
      which === "This"
        ? moment().startOf("month").startOf("day").format()
        : moment().add(1, "month").startOf("month").startOf("day").format();
    const end =
      which === "This"
        ? moment().endOf("month").endOf("day").format()
        : moment().add(1, "month").endOf("month").endOf("day").format();
    let EmployeeID = ''
    try {
      EmployeeID = TokenService.getEmployeeData().id
    } catch (error) {
      alert("Error: EmployeeID not found in Emply, please contact supervisor")
      return
    }
    const param = {
      start: start,
      end: end,
      UserUUID: TokenService.getUserID(),
      EmployeeID: EmployeeID,
    };

    const schedule = await ScheduleDataService.GetByDayAndUser(param);
    let array = [];
    for (const s of schedule.data) {
      const yearStart = parseFloat(moment(s.Date_start_time).format("YYYY"));
      const monthStart = parseFloat(moment(s.Date_start_time).format("M"));
      const dayStart = parseFloat(moment(s.Date_start_time).format("D"));
      const hourStart = parseFloat(moment(s.Date_start_time).format("H"));
      const minuteStart = parseFloat(moment(s.Date_start_time).format("m"));

      const yearEnd = parseFloat(moment(s.Date_end_time).format("YYYY"));
      const monthEnd = parseFloat(moment(s.Date_end_time).format("M"));
      const dayEnd = parseFloat(moment(s.Date_end_time).format("D"));
      const hourEnd = parseFloat(moment(s.Date_end_time).format("H"));
      const minuteEnd = parseFloat(moment(s.Date_end_time).format("m"));
      const start = [yearStart, monthStart, dayStart, hourStart, minuteStart];
      const end = [yearEnd, monthEnd, dayEnd, hourEnd, minuteEnd];

      const location =
        s.Location === "Office"
          ? "Trekronergade 126 F, 2500 København"
          : "Home office";

      const event = {
        title: "Work",
        start: start,
        end: end,
        location: location,
        description: "Work",
        busyStatus: "BUSY",
        status: "CONFIRMED",
        categories: ["WORK"],
      };
      if (s.Location === "Office") {
        event.geo = { lat: 55.65216, lon: 12.51981 };
        event.description = "Table booking " + s.Table_number
      }
      array.push(event);
    }

    ics.createEvents(array, (error, value) => {
      if (error) {
        alert("Something went wrong, please inform the IT department")
        return;
      }
      var file = new File([value], "work schedule.ics", {
        type: "text/plain;charset=utf-8",
      });
      FileSaver.saveAs(file);
    });

  }, []);

  //? Edit the schedule modal
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        pointerEvents: "none",
      }}
    >
      <Paper
        style={{
          maxHeight: "100vh",
          overflow: "hidden",
          margin: "auto",
          pointerEvents: "all",
        }}
        sx={{ width: { xs: "90%", lg: "30%" }, maxWidth: "420px", minWidth: "300px" }}
      >
        <Card
          className="Settings"
          style={{
            pointerEvents: "all",
          }}
        >
          <CardHeader style={{ paddingBottom: "0.1rem" }}
            title={
              <Alert color="info" severity="info">You can download an ICS file and import you work schedule to your personal scheduler.</Alert>
            }
            action={
              <IconButton
                onClick={() => props.handleClose()}
                color="primary"
                aria-label="download schedule"
                component="span"
              >
                <CloseIcon size="medium" sx={{ color: "white" }} />
              </IconButton>
            }
          />
          <Stack direction='column' textAlign="center" spacing={2} sx={{ margin: "1rem" }}>
            <Link color="inherit" target="_blank" underline="none" href="https://support.apple.com/en-gb/guide/calendar/icl1023/mac">
              Follow this link how to import Apple Calendar
            </Link>
            <Link color="inherit" target="_blank" underline="none" href="https://support.google.com/calendar/answer/37118?hl=en&co=GENIE.Platform%3DDesktop">
              Follow this link how to import into Google Calendar
            </Link>
          </Stack>
          <CardFooter>
            <Stack direction="row" spacing={1} flexGrow={1} justifyContent="center">
              <Button variant="contained" size="" onClick={() => createAndDownload("This")}>
                Download this month
              </Button>
              <Button variant="contained" onClick={() => createAndDownload("Next")}>
                Download next month
              </Button>
            </Stack>
          </CardFooter>
        </Card>
      </Paper>
    </div>
  );
}
