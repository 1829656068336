import http from "../Common_service/rest_http_common";
import AuthService from "services/REST_service/auth.service";

class EmplyService {


  async RetrieveDepartments() {
    const response = await http.get(`/Emply/depertments`);
    return response.data;
  }

  // async GetUserWUUID() {
  //   const user = AuthService.getCurrentUser();
  //   const response = await http.get(`/Emply/user/UserUUID/${user.id}`, {
  //   });
  //   return response.data;
  // }

  async GetMasterDataID(EmplyUUID, callback) {
    const response = await http.get(`/Emply/employee/${EmplyUUID}/api/masterData`);
    return callback(await response.data)
  }
  async GetMasterDataIDPOther(EmplyUUID) {
    const response = await http.get(`/Emply/employee/${EmplyUUID}/api/masterData`);
    return response.data;
  }

  // async GetContactDataID(EmplyUUID) {
  //   const response = await http.get(`/Emply/employee/${EmplyUUID}/contactData`);
  //   return response.data;
  // }

  async GetEmployeeByEmail(email) {
    const user = email ? email : AuthService.getCurrentUser().email;
    const response = await http.get(`/Emply/employee/${user}`);
    console.log(response)
    return response.data;

  }

  async GetMasterDataForm() {
    const response = await http.get(`/Emply/forms/masterdata`);
    return response.data
  }
  async CreateEditable(data) {
    const response = await http.post(`/Emply/forms/masterdata/editable`, data);
    return response.data
  }
  async RetrieveEditable() {
    const response = await http.get(`/Emply/forms/masterdata/editable`);
    return response.data
  }
  async UpdateEmployeeInfo(data, employeeId) {
    const response = await http.put(`/Emply/employee/${employeeId}/api/masterData`, data);
    return response.data;
  }

  // async GetAllEmplyEmployees() {
  //   const response = await http.get(`/Emply/employees`);

  //   return response.data;
  // }

  // async GetAllEmplyUsers() {
  //   const response = await http.get(`/Emply/users`);
  //   return response.data;
  // }
}


export default new EmplyService();
