import http from "../Common_service/azure_http_common";
import imageHTTP from "../Common_service/images_http_common";
import TokenService from "services/REST_service/token.service";
import moment from 'moment-timezone'
class GraphInfoService {
  async getMe() {
    const response = await http.get("/me", {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalBearerToken()}`,
      },
    });
    return response.data;
  }
  async GetMePic(user) {
    const response = await http
      .get(`/users/${user}/photos/120x120/$value`, {
        headers: {
          "Content-Type": "image/jpg",
          Authorization: `Bearer ${TokenService.getLocalBearerToken()}`,
        },
        responseType: "blob",
      })
      .catch(function (error) {
        // console.clear();
      });
    if (response === undefined) {
      return undefined;
    } else {
      return response.data;
    }
  }

  async GetUserInfo(email) {
    const response = await http
      .get(`/users/${email}`, {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalBearerToken()}`,
        },
      })
      .catch(function (error) {
        // console.clear();
      });
    if (response === undefined) {
      return undefined;
    } else {
      return response.data;
    }
  }
  async UpdateTask(data, todoTaskListId, taskId) {
    const response = await http.patch(
      `/me/todo/lists/${todoTaskListId}/tasks/${taskId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalBearerToken()}`,
        },
      }
    );
    return response.data;
  }
  async GetPresence(email) {
    const response = await http.get(`/users/${email}/presence`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalBearerToken()}`,
      },
    });
    return response.data;
  }

  async ChangeImage(imageFile) {
    const response = await imageHTTP.put(`/me/photo/$value`, imageFile, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalBearerToken()}`,
      },
    });
    return response;
  }
  async GetAdmins(id) {
    const response = await imageHTTP.get(`/groups/${id}/members`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalBearerToken()}`,
      },
    });
    return response.data;
  }

  async GetProjectGroups(startsWith) {
    const response = await imageHTTP.get(
      `/groups?$filter=startswith(displayName,'${startsWith}')`,
      {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalBearerToken()}`,
        },
      }
    );
    return response.data;
  }

  async GetUserEvents() {
    // const start = moment().utc().startOf('week').format()
    // const end = moment().utc().endOf('week').format()

    let timeStr = '09:01',
      date = moment(),
      time = moment(timeStr, 'HH:mm');

    date.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second')
    });

    let timeStr2 = '16:59',
      date2 = moment(),
      time2 = moment(timeStr2, 'HH:mm');

    date2.set({
      hour: time2.get('hour'),
      minute: time2.get('minute'),
      second: time2.get('second')
    });


    const start = date.utc().format();
    const end = date2.utc().format();

    const url = `https://graph.microsoft.com/v1.0/me/calendarview?startdatetime=${start}&enddatetime=${end}`;
    const response = await http.get(url, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalBearerToken()}`,
        Prefer: `outlook.timezone="${moment.tz.guess()}"`,
      },
    });
    return response.data;



  }



  // function initiateXMLHttpRequest(cb) {
  //     var token = retrieveAccessToken();
  //     var xhr = new XMLHttpRequest();
  //     xhr.responseType = 'application/json'
  //     if (token) {
  //       xhr.open('GET', `https://graph.microsoft.com/v1.0/me`, true);
  //       xhr.setRequestHeader('Authorization', `Bearer ${token}`);
  //       xhr.send(null);
  //     }

  //     xhr.onreadystatechange = function () {
  //       var DONE = 4;
  //       var OK = 200;
  //       if (xhr.readyState == DONE) {
  //         if (xhr.status == OK) cb(xhr);
  //       }
  //     };
  //   }
  //   function AjaxGetMe() {
  //     var token = retrieveAccessToken();
  //     return Promise.resolve($.ajax({
  //       type: "GET",
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //       },
  //       url: `https://graph.microsoft.com/v1.0/me`,
  //     }));
  //   }

  // get(id) {
  //     return http.get(`/Locations/${id}`);
  // }

  // create(data) {
  //     return http.post("/Locations/create", data);
  // }

  // update(id, data) {
  //     return http.put(`/Locations/${id}`, data);
  // }

  // delete(id) {
  //     return http.delete(`/Locations/${id}`);
  // }
}

export default new GraphInfoService();
