import { useContext } from "react";

import moment from "moment";
import GridItem from "./gridItem.jsx";
import styles from "App/Views/Components/classes";
import { makeStyles } from "@material-ui/styles";
import { Card, Stack } from "@mui/material";
import { OfficeScheduleState } from "context/store.js";
import useWindowSize from "App/Functions/useWindowSize.jsx";


const useStyles = makeStyles(styles);

export default function CalendarGrid() {
  const [Store] = useContext(OfficeScheduleState);
  const classes = useStyles();

  if (Store.Hours === null) {
    return <div>Nothing scheduled</div>;
  } else {
    return (
      <Card className="CalendarGridContainer" sx={{ height: "fit-content" }} >
        <Stack className="dayHeaders" direction={{ xs: "column-reverse", xxl: "row-reverse" }}>
          {Store.SelectedDays.WeekDays
            .slice(0)
            .reverse()
            .map((e, index) => {
              let RightDayPeople = [];
              for (const t of Store.People) {
                if (moment(t.Date_end_time).isSame(moment(e), "day")) {
                  RightDayPeople.push(t);
                }
              }
              if (Store.filterBy.Team !== undefined) {
                RightDayPeople = RightDayPeople.filter(
                  (e) => e.Team === Store.filterBy.Team
                );
              }
              if (Store.filterBy.Location !== undefined) {
                RightDayPeople = RightDayPeople.filter(
                  (e) => e.Location === Store.filterBy.Location
                );
              }
              if (
                Store.filterBy.Location !== undefined &&
                Store.filterBy.Team !== undefined
              ) {
                RightDayPeople = RightDayPeople.filter(
                  (e) =>
                    e.Location === Store.filterBy.Location && e.Team === Store.filterBy.Team
                );
              }
              if (Store.filterBy.People.length > 0) {
                // personCopy = Persons.filter(e => { if (filterBy.People.includes(e.User)) { return e } })
                let array = [];
                for (const e of Store.filterBy.People) {
                  for (const t of RightDayPeople) {
                    if (e === t.User_UUID) {
                      array.push(t);
                    }
                  }
                }
                RightDayPeople = array;
              }
              let Hours = 0;
              RightDayPeople.forEach((e) => {
                Hours = parseFloat(Hours) + parseFloat(e.Hours);
              });
              return (
                <Stack className="OfficeOneDay" key={index} sx={{ height: { xs: "fit-content", xl: "79vh" } }}>
                  <Stack direction="column" sx={{ marginTop: "1rem" }} alignItems="center">
                    <Stack direction="row" spacing={1} divider={<span>|</span>}>
                      <h5 className={classes.cardTitle}>
                        {moment(e).format("MMM-DD")}
                      </h5>
                      <h5 className={classes.cardTitle}>
                        {moment(e).format("dddd")}
                      </h5>
                    </Stack>
                    <p><strong>{Hours.toFixed(2)}</strong> hours | <strong>{RightDayPeople.length}</strong> people</p>
                  </Stack>
                  <GridItem
                    People={RightDayPeople}
                    filterBy={Store.filterBy}
                    key={index}
                    day={e}
                  ></GridItem>
                </Stack>
              );
            })}
        </Stack>
      </Card>
    );
  }
}
