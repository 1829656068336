import { useEffect, useState, useCallback, useContext, memo } from "react";
import { GlobalState } from "context/store.js";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { NotificationBot } from "services/Websocket";
// Theming
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import gbLocale from "date-fns/locale/en-GB";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ThemeProvider } from "@mui/material/styles";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import PreferenceService from "services/REST_service/preference.service";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
  setDarkMode,
} from "context";
// import routes from "App/routes";
import Sidenav from "App/Views/Components/Material_ui/Sidenav";
import MobileNav from "./Views/Components/Material_ui/MobileNav";
import brandWhite from "assets/img/logo.png";
import brandDark from "assets/img/logo.png";
import emplyLogo from "assets/img/emply-blue.png";

// import forOfor from "assets/img/notifications/404.png";
import { Typography } from "@mui/material";
import "assets/css/mgt.css";
import Notification from "App/Views/Components/Custom/Notification";
import TokenService from "services/REST_service/token.service";
import routes from "./Views/routes";
// import { RetrieveOptions } from "context/plugin";
import { EmplyProvider, getEmplyData } from "context/plugin";
import DashboardLayout from "App/Views/Components/Material_ui/DashboardLayout";
import DashboardNavbar from "App/Views/Components/Material_ui/DashboardNavbar";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingAppIndicator = ({ status }) => {
  return (
    <Box sx={{ display: "flex", width: "98vw", height: "98vh" }}>
      <Box
        sx={{
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress sx={{ margin: "2rem" }} />
        <Typography>{status}</Typography>
      </Box>
    </Box>
  );
};

const App = memo(() => {

  const [Store, setStore] = useContext(GlobalState);
  const [Status, setStatus] = useState("loading....");
  const [app, setApp] = useState(<LoadingAppIndicator status={Status} />);

  useEffect(() => {
    setApp(<LoadingAppIndicator status={Status} />);
  }, [Status]);

  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  const [controller, dispatch] = useMaterialUIController();

  const {
    miniSidenav,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);

  useEffect(() => {
    // Create a function for setting a variable value
    const check = document.querySelector("body");
    const r = document.querySelector(":root");

    if (darkMode === true) {
      if (check.classList.contains("mgt-light")) {
        check.classList.remove("mgt-light");
      }
      check.classList.add("mgt-dark");
      r.style.setProperty("--on_schedule_background", "transparent");
      r.style.setProperty("--on_schedule_color", "black");
    } else {
      if (check.classList.contains("mgt-dark")) {
        check.classList.remove("mgt-dark");
      }
      check.classList.add("mgt-light");
      r.style.setProperty("--on_schedule_background", "transparent");
      r.style.setProperty("--on_schedule_color", "#313d50");
    }
    // eslint-disable-next-line
  }, [darkMode]);

  const getRoutes = (allRoutes) => {
    let Routes = [];
    allRoutes.map((e) => {
      return e.map((t) => {
        if (t.type === "collapse") {
          return Routes.push(t);
        } else {
          return null;
        }
      });
    });
    return Routes.map((route) => {
      return (
        <Route
          exact
          path={route.subroute}
          // render={() => route.component}
          element={route.component}
          key={route.key}
        />
      );
    });
  };




  const { pathname } = useLocation();
  // Open sidenav when mouse enter on mini sidenav
  const getGraphData = useCallback(async () => {
    try {
      const data = await PreferenceService.get(await TokenService.getUserID());
      if (data.data !== "") {
        if (data.data !== null) {
          if (data.data !== undefined) {
            if (data.data.DarkMode === 1) {
              setDarkMode(dispatch, true);
            } else if (data.data.DarkMode === 0) {
              setDarkMode(dispatch, false);
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    setStatus("Logging you in...");

    const handleOnMouseEnter = () => {
      if (miniSidenav && !onMouseEnter) {
        setMiniSidenav(dispatch, false);
        setOnMouseEnter(true);
      }
    };
    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
      if (onMouseEnter) {
        setMiniSidenav(dispatch, true);
        setOnMouseEnter(false);
      }
    };

    let EmployeeData = await TokenService.getEmployeeData();
    if (EmployeeData.status !== 400) {
      try {
        setStatus("Contacting Emply for your employee data...");
        const EmplyData = await getEmplyData();
        setStore({ Emply: true });
        setApp(
          <EmplyProvider Emply={EmplyData}>
            <CssBaseline />
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              emplyLogo={emplyLogo}
              emplyActive={"success"}
              brandName="Dialogue Time"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              sx={{ display: { xs: "none", md: "block" } }}
            />
            <MobileNav />

            <DashboardLayout>
              <DashboardNavbar />
              <Routes>
                {getRoutes(routes)}
                <Route path="*" element={<Navigate to="/Dashboard" />} />
              </Routes>
            </DashboardLayout>
          </EmplyProvider>
        );
      } catch (error) {
        setStore({ Emply: false });
        setApp(
          <>
            <CssBaseline />
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              emplyLogo={emplyLogo}
              emplyActive={"error"}
              brandName="Dialogue Time"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />

            <DashboardLayout>
              <DashboardNavbar />
              <Routes>
                {getRoutes(routes)}
                <Route path="*" element={<Navigate to="/Dashboard" />} />
              </Routes>
            </DashboardLayout>
          </>
        );
      }
    }
  }, [
    setStore,
    setStatus,
    sidenavColor,
    dispatch,
    miniSidenav,
    onMouseEnter,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  ]);

  useEffect(() => {
    getGraphData();
    // eslint-disable-next-line
  }, []);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={gbLocale}>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <div
          style={{
            background: darkMode ? "#13181E" : "rgba(49, 61, 80, 0.145)",
            maxHeight: "100vh",
            minHeight: "100vh",
            overflowY: "scroll",
            // "#121212"
            // "linear-gradient(27deg, rgba(18,18,18,1) 0%, rgba(47,56,85,1) 100%)"
            // radial-gradient(circle, rgba(18,18,18,1) 0%, rgba(47,56,85,1) 100%);
          }}
        >
          {app}
          {Store.Notification && <Notification></Notification>}
          <NotificationBot style={{ display: "none" }}></NotificationBot>
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  );
});

export default App;
