import React from "react";
import {
  Person,
  PersonViewType,
} from "@microsoft/mgt-react";
import moment from "moment";
import { Divider } from "@mui/material";
import BusinessIcon from '@mui/icons-material/Business';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import IconButton from "@mui/material/IconButton";
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Stack from "@mui/material/Stack";
import useWindowSize from "App/Functions/useWindowSize";

export default function People(props) {
  const size = useWindowSize();

  return (
    <>
      <ListItem sx={{ width: "100%", height: "fit-content", marginTop: "0rem" }}>
        <ListItemAvatar sx={{ marginRight: "0.5rem", padding: "0.5rem" }}>
          <Person
            personQuery={props.info.Email}
            showPresence={true}
            view={PersonViewType.avatar}
            line1Property="givenName"
            avatarSize="large"
          />
        </ListItemAvatar>

        <ListItemText style={{ marginLeft: "auto" }} primary={props.info.name} secondary={moment(props.info.Date_start_time).format("HH:mm") + " - " + moment(props.info.Date_end_time).format("HH:mm")} />
        <Stack direction={size.width > 1399 && size.width < 1470 ? "column" : "row"}>
          <IconButton
            color="info"
            aria-label="upload picture"
            component="span"
          >
            {props.info.Location === "Office" && <BusinessIcon />}
            {props.info.Location === "Home Office" && <HomeWorkIcon />}
            {props.info.Location === "Business Trip" && <LocalAirportIcon />}
            {props.info.Location === "Vacation" && <BeachAccessIcon />}
          </IconButton>
          <IconButton
            color="info"
            aria-label="upload picture"
            component="span"
          >
            {props.info.worked && <CheckIcon />}
            {!props.info.worked && <CloseIcon />}
          </IconButton>
        </Stack>
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
}
