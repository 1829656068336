import Authenticate from "./App/authenticate.jsx";
import "assets/css/material-dashboard-react.css?v=1.10.0";
import "./index.css";
import { MaterialUIControllerProvider } from "context";
import "App/Views/Users/Schedule/schedule.css";
// import reportWebVitals from './reportWebVitals';
import 'context'
// Material Dashboard 2 React themes
// Material Dashboard 2 React Dark Mode themes
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <MaterialUIControllerProvider>
    <Authenticate></Authenticate>
  </MaterialUIControllerProvider>
);