import { useCallback, memo, useContext } from "react";
import CommandBar from "./CommandBar.jsx";
import Schedule from "./Schedule.jsx";
import moment from "moment";
import ScheduleDataService from "services/REST_service/schedule.service";
import { Stack } from "@mui/material";
import useWindowSize from "App/Functions/useWindowSize";
import { OfficeScheduleState } from "context/store.js";


function nextWeek(date) {
  const day = moment(date).add(1, "week");
  return day;
}
function previousWeek(date) {
  const day = moment(date).subtract(1, "week");
  return day;
}
const getDaysArray = function (start, end) {
  for (
    var arr = [], dt = new Date(start);
    dt <= end;
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};
moment.locale("en", { week: { dow: 1, doy: 4 } });
const thisMonday = moment().startOf("week");
const thisFriday = moment().startOf("week").add(4, "days");
const thisSunday = moment().endOf("week");

const OfficeSchedule = memo((props) => {
  const [Store, setStore] = useContext(OfficeScheduleState);
  const initial = useCallback(async (DaysToFetch) => {
    //? Update runtime to be 1, this will cause the UseEffect that calls this function to stop running to avoid endless rendering
    setStore({
      RunTime: 1,
      days: DaysToFetch,
    })
    //? Double check if the DaysToFetch is not empty
    if (DaysToFetch.length !== 0) {
      //? Create a parameter that will fetch data we need from the Schedule table, we want the beginning and end.
      const param = {
        start: moment(DaysToFetch[0]).startOf("day").format(),
        end: moment(DaysToFetch[DaysToFetch.length - 1])
          .endOf("day")
          .format(),
      };
      //? Find the dates from the schedule
      let people = await ScheduleDataService.ExportReport(param);
      setStore({
        People: people,
      })
    }
  }, [setStore]);

  const FilteredPersons = useCallback((array) => {
    const people = array.detail;
    let nameArray = [];
    for (const e of people) {
      nameArray.push(e.id);
    }
    setStore({
      filterBy: {
        ...Store.filterBy,
        People: nameArray,
      }
    })
  }, [Store, setStore]);


  const size = useWindowSize();


  function updateWeekArray(passed) {
    //? passed is either true or false. will make IsFullWeek t||f and update weekdays
    setStore({
      SelectedDays: {
        ...Store.SelectedDays,
        IsFullWeek: passed,
        WeekDays: passed
          ? getDaysArray(Store.SelectedDays.Monday, Store.SelectedDays.Sunday)
          : getDaysArray(Store.SelectedDays.Monday, Store.SelectedDays.Friday),
      }
    })
  }

  function updateTimeLine(currentTimeline) {
    if (currentTimeline === "Next") {
      setStore({
        SelectedDays: {
          ...Store.SelectedDays,
          Monday: nextWeek(Store.SelectedDays.Monday),
          Friday: nextWeek(Store.SelectedDays.Friday),
          Sunday: nextWeek(Store.SelectedDays.Sunday),
          WeekDays: Store.SelectedDays.IsFullWeek
            ? getDaysArray(
              nextWeek(Store.SelectedDays.Monday),
              nextWeek(Store.SelectedDays.Sunday)
            )
            : getDaysArray(
              nextWeek(Store.SelectedDays.Monday),
              nextWeek(Store.SelectedDays.Friday)
            ),
        }
      })
    } else if (currentTimeline === "Prev") {
      setStore({
        SelectedDays: {
          ...Store.SelectedDays,
          Monday: previousWeek(Store.SelectedDays.Monday),
          Friday: previousWeek(Store.SelectedDays.Friday),
          Sunday: previousWeek(Store.SelectedDays.Sunday),
          WeekDays: Store.SelectedDays.IsFullWeek
            ? getDaysArray(
              previousWeek(Store.SelectedDays.Monday),
              previousWeek(Store.SelectedDays.Sunday)
            )
            : getDaysArray(
              previousWeek(Store.SelectedDays.Monday),
              previousWeek(Store.SelectedDays.Friday)
            ),
        }
      })
    } else if (currentTimeline === "Today") {
      setStore({
        SelectedDays: {
          ...Store.SelectedDays,
          Monday: thisMonday,
          Friday: thisFriday,
          Sunday: thisSunday,
          WeekDays: Store.SelectedDays.IsFullWeek
            ? getDaysArray(thisMonday, thisSunday)
            : getDaysArray(thisMonday, thisFriday),
        }
      })
    }
  }

  function CalendarPicked(dateRangeArray) {
    const PickedMonday = moment(dateRangeArray[0]).startOf("week");
    const PickedSunday = moment(dateRangeArray[0]).endOf("week");
    const PickedFriday = moment(dateRangeArray[0])
      .startOf("week")
      .add(4, "days");
    setStore({
      SelectedDays: {
        ...Store.SelectedDays,
        Monday: PickedMonday,
        Friday: PickedFriday,
        Sunday: PickedSunday,
        WeekDays: Store.SelectedDays.IsFullWeek
          ? getDaysArray(PickedMonday, PickedSunday)
          : getDaysArray(PickedMonday, PickedFriday),
      }
    })
  }

  return (
    <div className="officeScheduleContainer" style={{ width: "95%", height: "90%", margin: "auto", overflow: "scroll", borderRadius: "5px" }}>
      {Store && (
        <Stack direction={size.width < 500 ? "column" : "row"} flexWrap='wrap'>
          <CommandBar
            handleClose4={props.handleClose4}
            FilteredPersons={FilteredPersons}
            CalendarPicked={CalendarPicked}
            updateWeekArray={updateWeekArray}
            updateTimeLine={updateTimeLine}
          ></CommandBar>
          <Schedule
            initial={initial}
          ></Schedule>
        </Stack>
      )}
    </div>
  );

});

export default OfficeSchedule;
