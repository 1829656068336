/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React helper functions
import pxToRem from "assets/theme-dark/functions/pxToRem";

// Material Dashboard 2 React base styles
import colors from "assets/theme-dark/base/colors";
import boxShadows from "assets/theme-dark/base/boxShadows";
import borders from "assets/theme-dark/base/borders";
import card from "assets/theme/components/card";

const { transparent, white, background } = colors;

const { md } = boxShadows;
const { borderRadius } = borders;
const hexToRgb = (input) => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase();
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};
const popover = {
  styleOverrides: {
    paper: {
      backgroundColor: background.paper,
      boxShadow: md,
      padding: pxToRem(8),
      borderRadius: borderRadius.md,
      color: white.main,
    },
    "&:before": {
      position: "absolute",
      content: '""',
      top: 0,
      left: 0,
      right: 0,
      width: "100%",
      height: "100%",
      background: "rgba(" +
        hexToRgb(white.main) +
        ", 0.08)",
      // background:
      // "linear-gradient(45deg, " + blackColor + ", " + infoColor[1] + ") !important",
      color: "inherit",
      zIndex: `${0} !important`,
      borderRadius: borderRadius.xl,
    },

  },
};

export default popover;
