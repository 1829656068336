/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";

// Material Dashboard 2 React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { dark, transparent, white } = colors;

const divider = {
  styleOverrides: {
    root: {
      backgroundColor: dark.main,
      height: pxToRem(1),
      margin: `${pxToRem(6)} auto`,
      borderBottom: "none",
      opacity: 0.10,
      width: "80%"
    },

    vertical: {
      backgroundColor: dark.main,
      width: pxToRem(1),
      height: "90%",
      margin: `auto ${pxToRem(16)}`,
      borderRight: "none",
      opacity: 0.10,
    },

    light: {
      backgroundColor: white.main,
      opacity: 0.20,

      "&.MuiDivider-vertical": {
        backgroundColor: white.main,
        opacity: 0.20,
      },
    },
  },
};

export default divider;
