import forOfor from "assets/img/notifications/404.png";
import fiveo3 from "assets/img/notifications/503.png";
import MDBox from "App/Views/Components/Material_ui/MDBox/index.js";

export const NoMatch = () => {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <MDBox
        component='img'
        src={forOfor}
        alt='Brand'
        width='2rem'
        sx={{ width: "100%", height: "auto" }}
      />
    </div>
  );
};

export const ServiceFailed = () => {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <MDBox
        component='img'
        src={fiveo3}
        alt='Brand'
        width='2rem'
        sx={{ width: "100%", height: "auto" }}
      />
    </div>
  );
};